import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $('body').on('click', '.work-free-action', function(event) {
      let elementId = $(this).data('target');

      if ($(elementId).hasClass("show")) {
        $(elementId).removeClass("show");
      } else {
        $(elementId).addClass("show");
      }


      return event.preventDefault();
    });
  }
}
